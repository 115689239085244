import React from 'react'
import CustomContainer from './CustomContainer'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants'
import { LazyLoadImage } from "react-lazy-load-image-component";
const Footer = () => {
    return (
        <div className='footer'>
            <CustomContainer size={10}>
                <ul className='footer_link'>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/about-us'>About Us</Link></li>
                    <li><Link to='/portfolio'>Portfolio</Link></li>
                    {/* <li><Link to='/animation'>Animation</Link></li> */}
                    <li><Link to='/contact-us'>Contact</Link></li>
                    {/* <li><Link to='/'>Native UGC</Link></li> */}
                </ul>
                <hr />
                <div className='subFooter'>

                    <p>© {new Date().getFullYear()} Pixel Motus LLC</p>
                    <LazyLoadImage
                        src={IMAGES.footerlogo}
                        effect="blur"
                        className='img-fluid portfolio'
                        alt={'Logo'}
                    />
                </div>
            </CustomContainer>
        </div>
    )
}

export default Footer